import { KEYWORD_ACNE, KEYWORD_EYE, KEYWORD_SERUM, KEYWORD_SKIN } from '../constants/funnels'
import {
  answersAcneRequestSelector,
  answersEyeRequestSelector,
  answersRequestSelector,
  answersSelector,
  answersSelectorAcne,
  answersSelectorEye,
  answersSelectorSerum,
  answersSerumRequestSelector
} from './selectors'
import {
  ACNE_QUESTIONNAIRE_OPENING_PAGE_URI,
  ACNE_TESTIMONIALS_PAGE_NAME,
  CONCERN_QUESTION,
  CONCERN_QUESTION_ACNE,
  CONCERN_QUESTION_EYE,
  CONCERN_QUESTION_SERUM,
  EYE_QUESTIONNAIRE_FIRST_QUESTION_URI,
  EYE_QUESTIONNAIRE_OPENING_PAGE_URI,
  EYE_QUESTIONNAIRE_URI,
  QUESTIONNAIRE_ID_ACNE,
  QUESTIONNAIRE_ID_EYE_CREAM_PASSWORDLESS,
  QUESTIONNAIRE_ID_SERUM,
  QUESTIONNAIRE_ID_SKIN_PASSWORDLESS,
  SERUM_QUESTIONNAIRE_FIRST_QUESTION_URI,
  SERUM_QUESTIONNAIRE_OPENING_PAGE_URI,
  SERUM_QUESTIONNAIRE_URI,
  SKIN_QUESTIONNAIRE_FIRST_QUESTION_URI,
  SKIN_QUESTIONNAIRE_OPENING_PAGE_URI,
  SKIN_QUESTIONNAIRE_URI,
  TEMPLATE_OF_QUESTIONNAIRE_INITIAL_REDUX_STATE,
  ACNE_QUESTIONNAIRE_FIRST_QUESTION_URI,
  ACNE_QUESTIONNAIRE_BASE_PATH
} from '../constants/configs/questionnaire'
import { cloneDeep } from 'lodash'
import { BRANDING_V4_THEME } from '../constants/constants'

const QUESTIONNAIRE_INFO = {
  [QUESTIONNAIRE_ID_SKIN_PASSWORDLESS]: {
    id: QUESTIONNAIRE_ID_SKIN_PASSWORDLESS,
    firstQuestionCached: CONCERN_QUESTION,
    questionnaireUri: SKIN_QUESTIONNAIRE_URI,
    questionnaireBasePath: SKIN_QUESTIONNAIRE_URI,
    questionnaireUriFirstQuestion: SKIN_QUESTIONNAIRE_FIRST_QUESTION_URI,
    questionnaireUriOpeningPage: SKIN_QUESTIONNAIRE_OPENING_PAGE_URI,
    congratsPageUri: '/account/congratulations',
    eventNameOnInvalidNextProps: 'quiz',
    type: KEYWORD_SKIN,
    answersRequestSelector: answersRequestSelector,
    answersSelector: answersSelector,
    reduxInitialState: TEMPLATE_OF_QUESTIONNAIRE_INITIAL_REDUX_STATE,
    hasSummaryPage: true
  },
  [QUESTIONNAIRE_ID_EYE_CREAM_PASSWORDLESS]: {
    id: QUESTIONNAIRE_ID_EYE_CREAM_PASSWORDLESS,
    firstQuestionCached: CONCERN_QUESTION_EYE,
    questionnaireUri: EYE_QUESTIONNAIRE_URI,
    questionnaireBasePath: EYE_QUESTIONNAIRE_URI,
    questionnaireUriFirstQuestion: EYE_QUESTIONNAIRE_FIRST_QUESTION_URI,
    questionnaireUriOpeningPage: EYE_QUESTIONNAIRE_OPENING_PAGE_URI,
    congratsPageUri: '/account/congratulations/eye',
    eventNameOnInvalidNextProps: 'quiz/eye',
    type: KEYWORD_EYE,
    answersRequestSelector: answersEyeRequestSelector,
    answersSelector: answersSelectorEye,
    reduxInitialState: TEMPLATE_OF_QUESTIONNAIRE_INITIAL_REDUX_STATE
  },
  [QUESTIONNAIRE_ID_SERUM]: {
    id: QUESTIONNAIRE_ID_SERUM,
    firstQuestionCached: CONCERN_QUESTION_SERUM,
    questionnaireUri: SERUM_QUESTIONNAIRE_URI,
    questionnaireBasePath: SERUM_QUESTIONNAIRE_URI,
    questionnaireUriFirstQuestion: SERUM_QUESTIONNAIRE_FIRST_QUESTION_URI,
    questionnaireUriOpeningPage: SERUM_QUESTIONNAIRE_OPENING_PAGE_URI,
    congratsPageUri: '/account/congratulations/serum',
    eventNameOnInvalidNextProps: 'quiz/serum',
    type: KEYWORD_SERUM,
    answersRequestSelector: answersSerumRequestSelector,
    answersSelector: answersSelectorSerum,
    reduxInitialState: TEMPLATE_OF_QUESTIONNAIRE_INITIAL_REDUX_STATE
  },
  [QUESTIONNAIRE_ID_ACNE]: {
    id: QUESTIONNAIRE_ID_ACNE,
    firstQuestionCached: CONCERN_QUESTION_ACNE,
    questionnaireUri: ACNE_QUESTIONNAIRE_FIRST_QUESTION_URI,
    questionnaireBasePath: ACNE_QUESTIONNAIRE_BASE_PATH,
    questionnaireUriFirstQuestion: ACNE_QUESTIONNAIRE_FIRST_QUESTION_URI,
    questionnaireUriOpeningPage: ACNE_QUESTIONNAIRE_OPENING_PAGE_URI,
    congratsPageUri: '/account/congratulations',
    eventNameOnInvalidNextProps: 'quiz/acne',
    type: KEYWORD_ACNE,
    answersRequestSelector: answersAcneRequestSelector,
    answersSelector: answersSelectorAcne,
    reduxInitialState: TEMPLATE_OF_QUESTIONNAIRE_INITIAL_REDUX_STATE,
    useOnlyQuestionsWithValidTypeForProgressPercentCalculation: true,
    hideIngredients: true,
    hasSummaryPage: true,
    quizEndingPage: ACNE_TESTIMONIALS_PAGE_NAME,
    whiteBackground: true,
    replacementTheme: BRANDING_V4_THEME,
    useStyleFromQuestion: true
  }
  /* PLEASE ADD NEW QUIZ HERE */
}

export const questionnairesInitialReduxState = Object.keys(QUESTIONNAIRE_INFO).reduce(
  (obWithQuestionnariesInitialStates, qId) => {
    obWithQuestionnariesInitialStates[qId] = cloneDeep(QUESTIONNAIRE_INFO[qId].reduxInitialState)
    obWithQuestionnariesInitialStates[qId].answers.id = qId
    return obWithQuestionnariesInitialStates
  },
  {}
)

/**
 * @param keyword (currently: 'skin', 'eye', 'serum' or 'acne')
 * @returns {{answersRequestSelector: OutputSelector<unknown, *&{firstName, lastName, zipCode: *, countryCode: *, answers: *}, (res1: unknown, res2: unknown, res3: unknown) => (*&{firstName, lastName, zipCode: *, countryCode: *, answers: *})>, answersSelector: (*|(function(*, *): (*))|(function(*): (*))), congratsPageUri: string, eventName: string, questionnaireUri: string, firstQuestionCached: {question: {validityCheck: boolean, heading: string, groupImage: string, type: string, transition: {title: boolean, status: string}, createdAt: string, subHeading: null, __v: number, name: string, _id: string, items: [{text: string, value: number},{text: string, value: number},{text: string, value: number},{text: string, value: number},{text: string, value: number},null,null,null], group: string, transitionB: {title: boolean, status: string}, updatedAt: string}, _id: string}, type: string, questionnaireUriFirstQuestion: string}|*}
 */
export const getQuestionnaireInfoByProductKeyword = keyword => {
  const keywordToUser = keyword || KEYWORD_SKIN
  const questionnaireIdOfKeyword = Object.keys(QUESTIONNAIRE_INFO).find(
    questionnaireId => QUESTIONNAIRE_INFO[questionnaireId]?.type === keywordToUser
  )

  if (questionnaireIdOfKeyword && QUESTIONNAIRE_INFO[questionnaireIdOfKeyword]) {
    return QUESTIONNAIRE_INFO[questionnaireIdOfKeyword]
  } else {
    console.warn(
      'questionnaireInfo not found for keyword:' +
        keywordToUser +
        ' . It will return skin questionnaireInfo as default'
    )
    return QUESTIONNAIRE_INFO[QUESTIONNAIRE_ID_SKIN_PASSWORDLESS]
  }
}
/**
 * Returns an array where every element is questionnaireinfo object
 * @returns array of {{answersRequestSelector: OutputSelector<unknown, *&{firstName, lastName, zipCode: *, countryCode: *, answers: *}, (res1: unknown, res2: unknown, res3: unknown) => (*&{firstName, lastName, zipCode: *, countryCode: *, answers: *})>, answersSelector: (*|(function(*, *): (*))|(function(*): (*))), congratsPageUri: string, eventName: string, questionnaireUri: string, firstQuestionCached: {question: {validityCheck: boolean, heading: string, groupImage: string, type: string, transition: {title: boolean, status: string}, createdAt: string, subHeading: null, __v: number, name: string, _id: string, items: [{text: string, value: number},{text: string, value: number},{text: string, value: number},{text: string, value: number},{text: string, value: number},null,null,null], group: string, transitionB: {title: boolean, status: string}, updatedAt: string}, _id: string}, type: string, questionnaireUriFirstQuestion: string}|*}
 */
export const getAllQuestionnaireInfo = () => {
  return Object.values(QUESTIONNAIRE_INFO)
}
export const getQuestionnaireInfo = questionnaireId => {
  if (questionnaireId) {
    return QUESTIONNAIRE_INFO[questionnaireId]
  } else {
    return QUESTIONNAIRE_INFO[QUESTIONNAIRE_ID_SKIN_PASSWORDLESS]
  }
}
