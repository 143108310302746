import { createTheme, useTheme } from '@mui/material/styles'
import { breakpoints } from './responsive'
import { prepareComponents } from './components'
import { prepareTypography } from './typography'
import { customColors } from './colors'
import { brandingV4CustomColors } from './brandingV4Colors'
import { prepareLogos } from './logos'
import { prepareShadows } from './shadows'
import { prepareIcons } from './icons'
import { BRANDING_V4_THEME } from '../../constants/constants'

const buildThemeOptions = colorsTheme => {
  return {
    breakpoints: {
      values: {
        ...breakpoints
      }
    },
    shape: {
      borderRadius: 8
    },
    palette: {
      mode: 'light',
      background: {
        default: customColors.gray.white,
        dark: customColors.gray.black,
        lightBlue: customColors.gray.lightBlue
      },
      text: {
        primary: customColors.gray.black,
        secondary: customColors.primary.main,
        disabled: customColors.gray.middleGray
      },
      ...colorsTheme
    }
  }
}

export const createProvenTheme = themeName => {
  const colorsTheme = themeName === BRANDING_V4_THEME ? brandingV4CustomColors : customColors
  let theme = createTheme(buildThemeOptions(colorsTheme))
  theme = prepareIcons(theme)
  theme = prepareTypography(theme)
  theme = prepareShadows(theme)
  theme = prepareLogos(theme)
  theme = prepareComponents(theme, themeName)
  //console.log('==== theme', theme)

  return theme
}

/**
 * This function is used for places where you want to use the old breakpoint but keep the rest of the proven theme.
 * We define the breakpoints to make branding-R1 keep the old breakpoints, since muiV5 changes the default breakpoints from v4
 * @param breakpoints
 * @param themeName
 * @returns {Theme}
 */
//TODO this function is going to be removed on this ticket: ENG-1471 in order to unify breakpoints (we'r going to use only createProvenTheme)
export const createProvenThemeWithBreakpoints = (breakpoints, themeName) => {
  const colorsTheme = themeName === BRANDING_V4_THEME ? brandingV4CustomColors : customColors

  let theme = createTheme({
    ...buildThemeOptions(colorsTheme),
    breakpoints
  })
  theme = prepareIcons(theme)
  theme = prepareTypography(theme)
  theme = prepareShadows(theme)
  theme = prepareLogos(theme)
  theme = prepareComponents(theme, themeName)
  //console.log('==== theme', theme)

  return theme
}
