import { onDesktop } from '../responsive'
import { experimental_sx as sx } from '@mui/material/styles'

const onDeviceThatSupportHover = '@media (hover: hover) and (pointer: fine)'
const onDeviceThatUsesTouchAndDoesNotSupportHover = '@media (hover: none) and (pointer: coarse)'

//TODO after switching 100% of pages to new Branding V4 replace button.js with this content
export const prepareButtonV4 = theme => {
  return {
    defaultProps: {
      disableRipple: true,
      disableElevation: true
    },
    styleOverrides: {
      root: sx({
        boxShadow: theme.shadows[1],
        '&.Mui-disabled': {
          boxShadow: theme.shadows[1],
          color: theme.palette.gray.middleGray,
          backgroundColor: theme.palette.gray.lightGray
        },
        '& .MuiButton-iconSizeSmall svg': {
          fontSize: '0.5rem'
        },
        '& .MuiButton-iconSizeMedium svg': {
          fontSize: '0.875rem'
        },
        '& .MuiButton-iconSizeLarge svg': {
          fontSize: theme.icons.large.fontSize
        }
      }),
      disableElevation: sx({
        boxShadow: theme.shadows[0],
        '&.Mui-disabled': {
          boxShadow: theme.shadows[0]
        }
      }),
      sizeMedium: {
        height: 44,
        borderRadius: 22,
        paddingLeft: theme.spacing(4),
        paddingRight: theme.spacing(4),
        paddingTop: theme.spacing(1.5),
        paddingBottom: theme.spacing(1.5),
        [onDesktop]: {
          height: theme.spacing(6),
          borderRadius: 24
        }
      },
      sizeSmall: {
        height: 30,
        borderRadius: 15,
        paddingLeft: theme.spacing(3),
        paddingRight: theme.spacing(3),
        [onDesktop]: {
          height: theme.spacing(4),
          borderRadius: 16
        }
      },
      disabled: sx({
        color: theme.palette.gray.middleGray,
        '&.MuiButton-contained': {
          backgroundColor: theme.palette.gray.lightGray
        }
      }),
      containedPrimary: sx({
        backgroundColor: theme.palette.secondary.main,
        color: theme.palette.gray.black,
        '&:hover': {
          backgroundColor: theme.palette.secondary.main,
          [onDeviceThatSupportHover]: {
            color: theme.palette.gray.white,
            backgroundColor: theme.palette.primary.flow
          }
        },
        '&:active': {
          [onDeviceThatUsesTouchAndDoesNotSupportHover]: {
            color: theme.palette.gray.white,
            backgroundColor: theme.palette.primary.flow
          }
        },
        '&.MuiButton-sizeSmall': {
          color: theme.palette.gray.white,
          backgroundColor: theme.palette.primary.flow,
          '&.Mui-disabled': {
            boxShadow: theme.shadows[1],
            color: theme.palette.gray.middleGray,
            backgroundColor: theme.palette.gray.lightGray
          },
          '&:hover': {
            color: theme.palette.gray.white,
            backgroundColor: theme.palette.primary.flow,
            [onDeviceThatSupportHover]: {
              color: theme.palette.secondary.neon
            }
          },
          '&:active': {
            [onDeviceThatUsesTouchAndDoesNotSupportHover]: {
              color: theme.palette.secondary.neon
            }
          }
        }
      }),
      containedSecondary: sx({
        backgroundColor: theme.palette.gray.black,
        color: theme.palette.secondary.main,
        '&:hover': {
          backgroundColor: theme.palette.gray.black,
          [onDeviceThatSupportHover]: {
            color: theme.palette.gray.white,
            backgroundColor: theme.palette.gray.black
          },
          '& span': {
            [onDeviceThatSupportHover]: {
              transition: 'color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms',
              color: `${theme.palette.gray.white}`
            }
          },
          '& s': {
            [onDeviceThatSupportHover]: {
              transition: 'color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms',
              color: `${theme.palette.gray.white}`
            }
          }
        },
        '&:active': {
          [onDeviceThatUsesTouchAndDoesNotSupportHover]: {
            color: theme.palette.gray.white,
            backgroundColor: theme.palette.gray.black
          }
        },
        '& span': {
          transition: 'color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms',
          color: theme.palette.gray.black
        },
        '& s': {
          transition: 'color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms',
          color: theme.palette.gray.darkGray
        }
      }),
      outlinedPrimary: sx({
        color: theme.palette.gray.black,
        borderColor: theme.palette.gray.middleGray,
        '&:hover': {
          borderColor: theme.palette.gray.middleGray,
          [onDeviceThatSupportHover]: {
            color: theme.palette.primary.flow,
            borderColor: theme.palette.primary.flow,
            background: 'none'
          }
        },
        '&:active': {
          [onDeviceThatUsesTouchAndDoesNotSupportHover]: {
            color: theme.palette.primary.flow,
            borderColor: theme.palette.primary.flow,
            background: 'none'
          }
        },
        '&.Mui-disabled': {
          backgroundColor: 'transparent'
        }
      }),
      containedInfo: sx({
        textTransform: 'initial',
        backgroundColor: theme.palette.primary.light,
        color: theme.palette.gray.black,
        borderRadius: 8,
        letterSpacing: 0,
        '&:hover': {
          [onDeviceThatSupportHover]: {
            backgroundColor: theme.palette.primary.light,
            color: theme.palette.gray.black
          }
        }
      }),
      outlinedInfo: sx({
        textTransform: 'initial',
        color: theme.palette.gray.black,
        borderColor: theme.palette.gray.lightBlue,
        borderRadius: 8,
        letterSpacing: 0,
        '&:hover': {
          color: theme.palette.gray.black,
          borderColor: theme.palette.gray.lightBlue,
          background: 'none'
        }
      }),
      text: sx({
        '&:hover': {
          [onDeviceThatSupportHover]: {
            background: 'none'
          }
        },
        '&.Mui-disabled': {
          backgroundColor: 'transparent'
        }
      }),
      textPrimary: sx({
        color: theme.palette.gray.black,
        '&:hover': {
          [onDeviceThatSupportHover]: {
            color: theme.palette.primary.flow
          }
        },
        '&:active': {
          [onDeviceThatUsesTouchAndDoesNotSupportHover]: {
            color: theme.palette.primary.flow
          }
        }
      }),
      icon: {
        minWidth: 'unset',
        paddingLeft: '0 !important',
        paddingRight: '0 !important',
        '&:hover': {
          [onDeviceThatSupportHover]: {
            background: 'none'
          }
        }
      },
      iconPrimary: {
        minWidth: 'unset',
        paddingLeft: '0 !important',
        paddingRight: '0 !important',
        color: theme.palette.gray.black,
        '&:hover': {
          [onDeviceThatSupportHover]: {
            color: theme.palette.primary.main
          }
        }
      }
    }
  }
}
