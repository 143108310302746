export const brandingV4CustomColors = {
  //TODO rename category references to neutral and then rename to neutral
  gray: {
    //TODO after switching 100% of pages to new Branding V4 replace references to black by 'oldBlack' and then
    // replace elysian references to 'black'
    black: '#000000',
    elysian: '#252522',

    darkGray: '#6A6A6A',
    middleGray: '#D2D0C7',
    lightGray: '#F2F1EB',
    whitesmoke: '#F5F5F5',
    beige: '#FAFAF5',
    lightBlue: '#F0F7FA',
    white: '#FFFFFF'
  },
  primary: {
    flow: '#43606E',
    middleBlue: '#CFDBDE',
    darkBlue: '#303C42',
    lightBlue: '#F0F7FA',

    main: '#256376',
    blue: '#1877F2',
    light: '#D6EBF2',
    current: '#95C8D8', //TODO rename this and all its references to provenBlue
    ether: '#D6EBF2', //TODO rename this and all its references to middleBLue

    contrastText: '#ffffff'
  },
  secondary: {
    main: '#CCFF00',
    neon: '#CCFF00',
    contrastText: '#303C42',
    darkSteelBlue: '#808892',
    steelBlue: '#ADB6C1'
  },
  error: {
    main: '#CE2E47',
    secondary: '#FDF3F5'
  },
  success: {
    main: '#1A7030',
    secondary: '#F4FBF8'
  },
  warning: {
    main: '#FF9900',
    secondary: '#FDF3F5'
  },
  black: {
    main: '#000000',
    secondary: '#FFFFFF'
  }
}

export const brandingV4TextColors = [
  'gray.black',
  'gray.white',
  'gray.darkGray',
  'gray.middleGray',
  'primary.main',
  'error.main',
  'warning.main',
  'success.main',
  'secondary.main'
]
