import React from 'react'

const DefaultIcon = () => (
  <svg width="20" height="21" viewBox="0 0 20 21" fill="none" xmlns="http://www.w3.org/2000/svg">
    <rect
      x="0.75"
      y="1.25"
      width="18.5"
      height="18.5"
      rx="9.25"
      stroke="#6B757D"
      strokeWidth="1.5"
    />
  </svg>
)

const SelectedIcon = () => (
  <svg width="20" height="21" viewBox="0 0 20 21" fill="none" xmlns="http://www.w3.org/2000/svg">
    <rect y="0.5" width="20" height="20" rx="10" fill="#256376" />
    <rect x="7" y="7.5" width="6" height="6" rx="3" fill="white" />
  </svg>
)

export const prepareRadio = theme => {
  return {
    defaultProps: {
      disableRipple: true,
      checkedIcon: <SelectedIcon />,
      icon: <DefaultIcon />
    },
    styleOverrides: {
      root: {
        color: theme.palette.gray.darkGray
      },
      checked: {
        color: theme.palette.primary.main
      }
    }
  }
}
