import { onDesktop } from './responsive'

export const prepareTypographyH1 = theme => {
  return {
    fontSize: 64,
    lineHeight: '100%',
    fontFamily: '"Freight Big Comp Pro", serif',
    fontStyle: 'normal',
    fontWeight: 400,
    letterSpacing: 0,
    textTransform: 'capitalize',
    color: theme.palette.text.primary,
    [onDesktop]: {
      fontSize: 80
    }
  }
}

export const prepareTypographyH2 = theme => {
  return {
    fontSize: 48,
    lineHeight: '110%',
    fontFamily: '"Freight Big Comp Pro", serif',
    fontStyle: 'normal',
    fontWeight: 400,
    letterSpacing: 0,
    textTransform: 'capitalize',
    color: theme.palette.text.primary,
    [onDesktop]: {
      fontSize: 64
    }
  }
}

export const prepareTypographyH2Italic = theme => {
  return {
    fontSize: 48,
    lineHeight: '110%',
    fontFamily: '"Freight Big Comp Pro", serif',
    fontStyle: 'italic',
    fontWeight: 400,
    letterSpacing: 0,
    textTransform: 'capitalize',
    color: theme.palette.text.primary,
    [onDesktop]: {
      fontSize: 64
    }
  }
}

export const prepareTypographyH3 = theme => {
  return {
    fontSize: 38,
    lineHeight: '100%',
    fontFamily: '"Freight Big Comp Pro", serif',
    fontStyle: 'normal',
    fontWeight: 400,
    letterSpacing: 0,
    textTransform: 'capitalize',
    color: theme.palette.text.primary,
    [onDesktop]: {
      fontSize: 48
    }
  }
}

export const prepareTypographyH4 = theme => {
  return {
    fontSize: 30,
    lineHeight: '110%',
    fontFamily: '"Freight Big Comp Pro", serif',
    fontStyle: 'normal',
    fontWeight: 400,
    letterSpacing: 0,
    textTransform: 'capitalize',
    color: theme.palette.text.primary,
    [onDesktop]: {
      fontSize: 38
    }
  }
}

export const prepareTypographyH5 = theme => {
  return {
    fontSize: 24,
    lineHeight: '120%',
    fontFamily: '"Inter", sans-serif',
    fontStyle: 'normal',
    fontWeight: 600,
    letterSpacing: 0,
    color: theme.palette.text.primary,
    [onDesktop]: {
      fontSize: 28
    }
  }
}

export const prepareTypographyH6 = theme => {
  return {
    fontSize: 20,
    lineHeight: '120%',
    fontFamily: '"Inter", sans-serif',
    fontStyle: 'normal',
    fontWeight: 600,
    letterSpacing: 0,
    color: theme.palette.text.primary,
    [onDesktop]: {
      fontSize: 24
    }
  }
}

export const prepareTypographyTitle1 = theme => {
  return {
    letterSpacing: '0.04em',
    textTransform: 'uppercase',
    fontFamily: '"Inter", sans-serif',
    fontStyle: 'normal',
    fontWeight: 600,
    fontSize: 12,
    lineHeight: '120%',
    color: theme.palette.text.primary,
    [onDesktop]: {
      fontSize: 16,
      lineHeight: '150%'
    }
  }
}

export const prepareTypographyTitle2 = theme => {
  return {
    letterSpacing: 0,
    fontFamily: '"Inter", sans-serif',
    fontStyle: 'normal',
    fontWeight: 600,
    fontSize: 16,
    lineHeight: '150%',
    color: theme.palette.text.primary,
    [onDesktop]: {
      fontSize: 18
    }
  }
}

export const prepareTypographyBody1 = theme => {
  return {
    fontFamily: '"Inter", sans-serif',
    fontStyle: 'normal',
    fontWeight: 400,
    fontSize: 16,
    lineHeight: '150%',
    letterSpacing: 0,
    color: theme.palette.gray.darkGray,
    [onDesktop]: {
      fontSize: 18
    }
  }
}

export const prepareTypographyBody2 = theme => {
  return {
    fontFamily: '"Inter", sans-serif',
    fontStyle: 'normal',
    fontWeight: 400,
    fontSize: 14,
    lineHeight: '150%',
    letterSpacing: 0,
    color: theme.palette.gray.darkGray,
    [onDesktop]: {
      fontSize: 16
    }
  }
}

export const prepareTypographyBody2Bold = theme => {
  return {
    fontFamily: '"Inter", sans-serif',
    fontStyle: 'normal',
    fontWeight: 600,
    fontSize: 14,
    lineHeight: '150%',
    letterSpacing: 0,
    color: theme.palette.gray.darkGray,
    [onDesktop]: {
      fontSize: 16
    }
  }
}

export const prepareTypographyHyperlinkStyle = theme => {
  return {
    textDecorationLine: 'underline',
    color: theme.palette.text.primary,
    '&:hover': {
      color: theme.palette.primary.main,
      cursor: 'pointer'
    }
  }
}

export const prepareTypographyFootnote = theme => {
  return {
    letterSpacing: 0,
    fontFamily: '"Inter", sans-serif',
    fontStyle: 'normal',
    fontWeight: 400,
    fontSize: 12,
    lineHeight: '130%',
    color: theme.palette.gray.darkGray,
    [onDesktop]: {
      fontSize: 14
    }
  }
}

export const prepareTypography = theme => {
  theme.typography = {
    ...theme.typography,
    fontSize: 14,
    [onDesktop]: {
      fontSize: 16
    },
    fontFamily: '"Inter", sans-serif',
    h1: prepareTypographyH1(theme),
    h2: prepareTypographyH2(theme),
    h2Italic: prepareTypographyH2Italic(theme),
    h3: prepareTypographyH3(theme),
    h4: prepareTypographyH4(theme),
    h5: prepareTypographyH5(theme),
    body1: prepareTypographyBody1(theme),
    body2: prepareTypographyBody2(theme),
    body2Bold: prepareTypographyBody2Bold(theme),
    button: {
      fontFamily: '"Inter", sans-serif',
      fontStyle: 'normal',
      fontWeight: 600,
      fontSize: 13,
      lineHeight: '100%',
      letterSpacing: '0.1em',
      textTransform: 'uppercase',
      [onDesktop]: {
        fontSize: 14
      }
    },
    h6: prepareTypographyH6(theme),
    // disable unneeded MUI font variants
    overline: undefined,
    caption: undefined,
    subtitle1: undefined,
    subtitle2: undefined,
    // add custom font variants
    title1: prepareTypographyTitle1(theme),
    title2: prepareTypographyTitle2(theme),
    subtitle: {
      fontSize: 20,
      lineHeight: '150%',
      fontFamily: '"Freight Big Comp Pro", serif',
      fontStyle: 'italic',
      fontWeight: 700,
      letterSpacing: 0,
      color: theme.palette.text.primary,
      [onDesktop]: {
        fontSize: 24,
        lineHeight: '130%'
      }
    },
    hyperlink: {
      letterSpacing: 0,
      fontFamily: '"Inter", sans-serif',
      fontStyle: 'normal',
      fontWeight: 400,
      fontSize: 14,
      lineHeight: '150%',
      [onDesktop]: {
        fontSize: 16
      },
      ...prepareTypographyHyperlinkStyle(theme)
    },
    footnote: {
      ...prepareTypographyFootnote(theme)
    },
    footnoteLight: {
      letterSpacing: 0,
      fontFamily: '"Inter", sans-serif',
      fontStyle: 'normal',
      fontWeight: 300,
      fontSize: 12,
      lineHeight: '130%',
      color: theme.palette.gray.darkGray,
      [onDesktop]: {
        fontSize: 14
      }
    },
    footnoteBold: {
      letterSpacing: 0,
      fontFamily: '"Inter", sans-serif',
      fontStyle: 'normal',
      fontWeight: 600,
      fontSize: 12,
      lineHeight: '130%',
      color: theme.palette.gray.darkGray,
      [onDesktop]: {
        fontSize: 14
      }
    },
    priceSlashOut: {
      color: theme.palette.gray.darkGray,
      fontFamily: '"Inter", sans-serif',
      fontStyle: 'normal',
      fontWeight: 400,
      fontSize: 12,
      lineHeight: '120%',
      letterSpacing: '0.04em',
      textDecorationLine: 'line-through',
      textTransform: 'uppercase',
      fontFeatureSettings: '"liga" off',
      [onDesktop]: {
        fontSize: 16
      }
    },
    pillQuote: {
      fontSize: 20,
      lineHeight: '150%',
      fontFamily: '"Freight Big Comp Pro", serif',
      fontStyle: 'normal',
      fontWeight: 900,
      letterSpacing: 0,
      color: theme.palette.text.primary,
      [onDesktop]: {
        fontSize: 24,
        lineHeight: '130%'
      }
    }
  }

  return theme
}

export const allFontTypes = [
  'h1',
  'h2',
  'h2Italic',
  'h3',
  'h4',
  'h5',
  'h6',
  'title1',
  'title2',
  'subtitle',
  'body1',
  'body2',
  'body2Bold',
  'button',
  'hyperlink',
  'footnote',
  'footnoteLight',
  'footnoteBold',
  'priceSlashOut',
  'pillQuote'
]
