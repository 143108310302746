export const prepareTooltip = theme => {
  return {
    styleOverrides: {
      tooltip: {
        ...theme.typography.body2,
        backgroundColor: theme.palette.gray.beige,
        color: theme.palette.gray.darkGray,
        boxShadow: theme.shadows[1],
        padding: theme.spacing(3)
      }
    }
  }
}
