import {
  QUESTIONNAIRE_KEY_ZIPCODE,
  QUESTIONNAIRE_KEYS_NAME,
  QUIZ_ANALYTICS_EVENT_PREFIX
} from '../../constants/configs/questionnaire'
import { getQuestionnaireInfo } from '../questionnaire-configurator'
import transitionByQuestionMap from '../../constants/configs/transition-by-question-map'

export const getCongratPageUri = questionnaireId => {
  const questionnaireInfo = getQuestionnaireInfo(questionnaireId)
  const questionnaireInfoSkin = getQuestionnaireInfo()
  if (questionnaireInfo && questionnaireInfo?.congratsPageUri) {
    return questionnaireInfo.congratsPageUri
  } else {
    return questionnaireInfoSkin.congratsPageUri
  }
}

export const getQuizUriPrefix = questionnaireId => {
  const questionnaireInfo = getQuestionnaireInfo(questionnaireId)
  return questionnaireInfo.questionnaireBasePath?.endsWith('/')
    ? questionnaireInfo.questionnaireBasePath
    : questionnaireInfo.questionnaireBasePath + '/'
}

export const getAnswers = (questionnaireId, state) => {
  return getQuestionnaireInfo(questionnaireId).answersRequestSelector(state).answers
}

export const getNameFromAnswers = answers => {
  const questionnaireKeyNameFound =
    answers && QUESTIONNAIRE_KEYS_NAME.find(keyName => keyName in answers)
  return questionnaireKeyNameFound && answers[questionnaireKeyNameFound]
}

export const getQuizEndingPageUri = questionnaireId => {
  const questionnaireInfo = getQuestionnaireInfo(questionnaireId)
  if (!questionnaireInfo) {
    console.warn('No questionnaire info found for questionnaireId:', questionnaireId)
    return
  }
  if (!questionnaireInfo.quizEndingPage) {
    return
  }
  return `${questionnaireInfo.questionnaireBasePath}/${questionnaireInfo.quizEndingPage}`
}

export const getZipCodeFromAnswers = answers => {
  return answers[QUESTIONNAIRE_KEY_ZIPCODE]
}

export const getQuizTypeByQuestionnaireId = questionnaireId => {
  return getQuestionnaireInfo(questionnaireId).type
}

export const hasTransitionForQuestion = (questionnaireId, questionName) => {
  return Boolean(
    transitionByQuestionMap[questionnaireId] &&
      transitionByQuestionMap[questionnaireId][questionName]
  )
}

export const transitionIsInSomeNextQuestion = (
  questionnaireId,
  nextQuestionsNames,
  transitionName
) => {
  return nextQuestionsNames.some(questionName => {
    return (
      hasTransitionForQuestion(questionnaireId, questionName) &&
      getTransitionForQuestion(questionnaireId, questionName).transitionName === transitionName
    )
  })
}

export const getTransitionForQuestion = (questionnaireId, questionName) => {
  return hasTransitionForQuestion(questionnaireId, questionName)
    ? { ...transitionByQuestionMap[questionnaireId][questionName], questionnaireId }
    : {}
}

/** Returns an array of transitions with the following shape:
 * { questionnaireId: string, questionName: string, transitionName: string, isFullscreenTransition: boolean }
 * @returns {*[{ questionnaireId: string, questionName: string, transitionName: string, isFullscreenTransition: boolean }]}
 */
export const getAllTransitions = () => {
  return Object.entries(transitionByQuestionMap).reduce((acc, [questionnaireId, questionMap]) => {
    const transitionDataByQuestionNameWithoutTransitionDuplication = Object.entries(
      questionMap
    ).reduce((acc, [questionName, transitionData]) => {
      if (!Object.values(acc).some(t => t.transitionName === transitionData.transitionName)) {
        acc[questionName] = transitionData
      }
      return acc
    }, {})

    return [
      ...acc,
      ...Object.entries(transitionDataByQuestionNameWithoutTransitionDuplication).map(
        ([questionName, transitionInfo]) => ({
          questionnaireId,
          questionName,
          transitionName: transitionInfo.transitionName,
          isFullscreenTransition: transitionInfo.isFullscreenTransition
        })
      )
    ]
  }, [])
}

export const createAnalyticsEventNameForQuizQuestion = (questionName, isForGoogleVariant) => {
  return isForGoogleVariant
    ? `${QUIZ_ANALYTICS_EVENT_PREFIX}${questionName}_2`.toLowerCase().trim()
    : `${QUIZ_ANALYTICS_EVENT_PREFIX}${questionName}`.toLowerCase().trim()
}
export const createAnalyticsNameFieldValueForQuizPageShown = questionName => {
  return `${questionName}`.toLowerCase().trim()
}
